import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "../../tools.component/youtubeEmbed.component";
import mysqlServices from "../../../services/mysqlServices";
function Videos(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();
    const [videos, setVideos] = useState(null);
    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getResourcesVideos().then(data => {
                //console.log(data.data)
                setVideos(data.data);
            }).catch(err => {
                console.log(err);
            })
        } else {

        }
    }, [])

    return (
        <div className='display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-2-rem column-gap-2-rem overflow-y-hidden'>

            {(videos && videos.length > 0) ? videos.map((item, index) =>
   


                <div className="display-flex flex-direction-column coloumn-gap-0-5-rem row-gap-0-5-rem">
                    <span className='font-size-20-px bold'>{i18n.language === "fr" ? item.header_french : item.header_english}</span>
                    <div className="">
                        {/* <Video loop>
                            <source src={video} type="video/mp4" />
                         </Video> */}

                        {item.type === "youtube" ?
                            <YoutubeEmbed title={i18n.language === "fr" ? item.header_french : item.header_english} embedId={item.link} videoStyle="video-responsive" />
                            :
                            item.type === "vimeo" ?
                                <span>
                                    <iframe src={item.link} title="vimeo video" className="video-responsive" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                </span>
                                : null
                        }
                    </div>

                    <span className=''>{i18n.language === "fr" ? item.paragraph_french : item.paragraph_english}</span>
                </div>
            ) : null}

        </div>
    )
}
export default Videos;